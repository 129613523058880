@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

::-webkit-scrollbar {
    width: 4px;
    height: 80px;
  }
  
  ::-webkit-scrollbar-track {
    background:#222A35;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #575C66;
    border-radius: 6px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #626970;
  }

/* Global transition for all elements */
* {
  transition: background-color 0.3s ease, 
              color 0.3s ease, 
              border-color 0.3s ease, 
              box-shadow 0.3s ease,
              opacity 0.3s ease;
}

/* Ensure all divs and containers transition smoothly */
div, section, article, header, footer, nav, aside, main {
  transition: background-color 0.3s ease, 
              color 0.3s ease, 
              border-color 0.3s ease, 
              box-shadow 0.3s ease,
              opacity 0.3s ease;
}

/* Ensure all text elements transition smoothly */
h1, h2, h3, h4, h5, h6, p, span, a, li, button, input, textarea {
  transition: color 0.3s ease, 
              background-color 0.3s ease, 
              border-color 0.3s ease,
              opacity 0.3s ease;
}

/* Ensure all borders transition smoothly */
*[style*="border"] {
  transition: border-color 0.3s ease;
}

/* Ensure all backgrounds transition smoothly */
*[style*="background"] {
  transition: background-color 0.3s ease;
}